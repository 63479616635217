import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import { lazy, Suspense } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import Preloader from "./layout/Preloader.js";
const Home = lazy(() => import("./ui/Home.js"));
const Career = lazy(() => import("./ui/Career.js"));
const Contact = lazy(() => import("./ui/Contact.js"));
const About = lazy(() => import("./ui/About.js"));
const Service = lazy(() => import("./ui/Service.js"));
const Blog = lazy(() => import("./ui/Blog.js"));
const NoPage = lazy(() => import("./layout/NoPage.js"));

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={ <Preloader/>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Service />} />
            <Route path="careers" element={<Career />} />
            <Route path='blogs' element={<Blog />}/>
            <Route path="contact" element={<Contact />} />
            <Route path="*" element={<NoPage />} />       
          </Routes>
        </Suspense>
      </Router> 
    </>
  );
}

export default App;
